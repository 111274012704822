.App {
  text-align: center;
}

*{

  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.text-content {
  text-align: left;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.centered-text {   background-color: #fd97d7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f363a3;
  font-size: 125px;
  -webkit-text-stroke: 1px #000;
  text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.3);
  
}


.center {
  width:100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fd97d7;
}

.web-text{
  font-size: 30px;

}

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.skewed-button {
  padding: 10px 20px;
  background-color: #ff66b2;
  border: none;
  color: white;
  font-weight: bold;
  transform: rotate(-10deg);
  box-shadow: 3px 3px 0px #8a2954;
  cursor: pointer;
}


.skewed-button:nth-child(2) {
  transform: rotate(-5deg);
}

.skewed-button:nth-child(3) {
  transform: rotate(5deg);
}

.skewed-button:nth-child(4) {
  transform: rotate(10deg);
}

.skewed-button:hover {
  background-color: #ff3385;
}

.skewed-button:hover {
  background-color: #ff3385;
}

.divider {
  background: linear-gradient(to right, transparent 50%, black 50%);
  height: 20px;
  background-size: 40px 100%;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .centered-text {
    font-size: 100px;
  }
}

.section-2{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:100px;
  width: 100vw;
  
}

.image1 {
  width: 400px;
  height: 400px;
}


@media (max-width: 768px) {
  .image1 {
    width: 300px;
  height: 300px;
  }
}

@media (max-width: 768px) {
  .section-2 {
    display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
    font-size: 100px;
  }
}

.vertical-text{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.centered-text2 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f363a3;
  font-size: 75px;
  -webkit-text-stroke: 1px #000;
  text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .centered-text2 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f363a3;
    font-size: 55px;
    -webkit-text-stroke: 1px #000;
    text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.3);
  }
}

.web-text2{
  width: 500px;
  font-size: 20px;
}

@media (max-width: 768px) {
  .web-text2 {
    display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  font-size:14px;
  }
}

.divider {
  background-color: #fd97d7;
  transform: rotate(180deg);
}

.bg2{
  width: 80%;
  border: 1px solid #FF4A9A;
  padding: 10px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #FF4A9A;
  border-radius: 5px 5px 5px 5px;
}

.marquee-text {
  color: #ff4a99;
  font-weight: 600;
}

.final{
  background-color: black;
  width:100%;
  height: 100%;
  color:white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-text3 { 
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 125px;
  -webkit-text-stroke: 1px #f363a3;
  text-shadow: 3px 3px 0px #f363a3;
  
}

@media (max-width: 768px) {
  .centered-text3 {
    display: flex;
    align-items: center;
    color: black;
    font-size: 55px;
    -webkit-text-stroke: 1px #f363a3;
    text-shadow: 3px 3px 0px #f363a3;
  }
}

.copyright{
  padding:10px;
  font-weight: 600;
}

.slideshow {
  width: 80%;
  margin: 0 auto;
}

.slick-slide img {
  display: block;
  margin: 0 auto;
  width: 100%; /* Ensure the image takes up the full width of the slider */
  height: auto; /* Maintain the aspect ratio */
}
.slideshow-image {
  width: 100%;
}

@media (min-width: 1024px) {
  .slideshow-image {
    width: 20px;
    height: 20px;
  }
}


.mobile-only {
  display: none;
}

.desktop-only {
  display: block;
}

@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
}








